import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import App from "./App";
import config from "./config";
import "./index.css";

window.appConfig = config;

const root = createRoot(document.getElementById("root"));
const Auth0Wrapper = () => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    // Store the token in cookies when authenticated
    const storeTokenInCookies = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          // Store the token in a cookie, set expiration time to 1 hour (or adjust as needed)

          // Cookies.set("auth0_token", token, { expires: 1 });
          localStorage.setItem("auth0_token", token);
          console.log("Token stored in cookies:", token);
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }
    };

    storeTokenInCookies();
  }, [isAuthenticated, getAccessTokenSilently]);

  return <App />;
};

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-bsz8g2ina5lrwfw3.us.auth0.com"
      clientId="4Udq6qUC0lmHXJz3tDinX04nErAkFjaq"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <Auth0Wrapper />
    </Auth0Provider>
  </React.StrictMode>
);
