import React, { useState, useEffect, useCallback } from "react";
import {
  getServiceStatus,
  determineServiceStatus,
  startService,
  stopService,
} from "./apiclient";
import { useAuth0 } from "@auth0/auth0-react";
import config from "./config";

function ServiceStatus() {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [disabledButtons, setDisabledButtons] = useState({});
  const services_string = config.SERVICES_STRING;
  const { getAccessTokenSilently } = useAuth0();

  // Helper function to convert string to title case
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  // Helper function to format service name
  const formatServiceName = (key) => {
    return toTitleCase(key.replace(/\./g, " "));
  };

  const loadAndFetchServices = useCallback(async () => {
    const token = await getAccessTokenSilently();
    console.log("loadAndFetchServices called"); // Debug log
    // console.log("token", token);
    // Load initial services
    const serviceList = services_string.split(",") || [];
    const initialServices = serviceList.map((service) => ({
      key: service.trim(),
      name: formatServiceName(service.trim()),
      status: "Unknown",
      action: "Start",
    }));
    setServices(initialServices);

    // Fetch service statuses
    setIsLoading(true);
    try {
      const updatedServices = await Promise.all(
        initialServices.map(async (service) => {
          try {
            const response = await getServiceStatus(service.key);
            const status = determineServiceStatus(response);
            return {
              ...service,
              status: status,
              action: [
                "Running",
                "Starting",
                "Scaling-Up",
                "Scaling-Down",
              ].includes(status)
                ? "Stop"
                : "Start",
            };
          } catch (error) {
            console.error(`Error fetching status for ${service.name}:`, error);
            return service;
          }
        })
      );
      setServices(updatedServices);
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setIsLoading(false);
    }
  }, [services_string]); // Add services_string as a dependency

  useEffect(() => {
    loadAndFetchServices();
  }, [loadAndFetchServices]);

  async function handleStart(serviceKey) {
    console.log(`Starting ${serviceKey}`);

    try {
      await startService(serviceKey);
      await loadAndFetchServices();
    } catch (error) {
      console.error(`Error starting ${serviceKey}:`, error);
    }
  }

  async function handleStop(serviceKey) {
    console.log(`Stopping ${serviceKey}`);

    try {
      await stopService(serviceKey);
      await loadAndFetchServices();
    } catch (error) {
      console.error(`Error stopping ${serviceKey}:`, error);
    }
  }

  function handleRefresh() {
    console.log(`Refreshing status`);
    loadAndFetchServices();
  }
  //   if (isLoading) {
  //     return <div>Loading...</div>;
  //   }
  // console.log("services", services);
  return (
    // <section className="service-status">
    //     <h2>Your Projects</h2>
    <section className="md:text-y flex flex-col md:mx-auto justify-center">
      <h2 className="text-xs  text-left md:text-3xl my-0 md:my-10 font-medium ml-0 mr-20 font-montserrat mb-6  mt-8 md:">
        Our Projects
      </h2>
      <table>
        <thead>
          <tr>
            <th className="text-xs md:text-[15px] font-montserrat">Service</th>
            <th className="text-xs md:text-[15px] font-montserrat">
              Status
              <button
                className="refresh-btn ml-2"
                onClick={handleRefresh}
                aria-label="Refresh status"
              >
                &#x21BB;
              </button>
            </th>
            <th className="text-xs md:text-[15px] font-montserrat">Action</th>
          </tr>
        </thead>
        <tbody>
          {services.map((service) => (
            <tr key={service.key}>
              {/* <td className="text-xs md:text-[15px] font-montserrat">
                {service.status === "Running" ? (
                  <a
                    href={`https://${service.key}.neartekpod.io`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {service.name}
                  </a>
                ) : (
                  service.name
                )}
              </td> */}
              <td className="text-xs md:text-[15px] font-montserrat">
                {service.status === "Running" ? (
                  <a
                    href={`https://${service.key}.neartekpod.io`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    {service.name}
                  </a>
                ) : (
                  service.name
                )}
              </td>
              <td className="text-xs md:text[15px] font-montserrat">
                {isLoading ? "Loading..." : service.status}
              </td>
              <td>
                <button
                  className="action-btn items-center md: bg-logout p-2 text-xs md:text-sm"
                  onClick={() =>
                    service.action === "Start"
                      ? handleStart(service.key)
                      : handleStop(service.key)
                  }
                >
                  {service.action}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default ServiceStatus;
