import React from "react";
import { demoData } from "./DemoData"; // Import data file
const DemoTable = () => {
  return (
    <section className="md:text-y flex flex-col md:mx-auto justify-center">
      <h2 className="text-xs text-left md:text-3xl my-0 md:mb-10 font-medium ml-0 mr-20 font-montserrat mb-6">
        Our Demos
      </h2>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="text-xs md:text-[15px] font-montserrat">Title</th>
            <th className="text-xs md:text-[15px] font-montserrat">
              Description
            </th>
            <th className="text-xs md:text-[15px] font-montserrat">
              Demo Video
            </th>
          </tr>
        </thead>
        <tbody>
          {demoData.map((demo) => (
            <tr key={demo.key}>
              <td className="text-xs md:text-[15px] font-montserrat">
                {demo.title}
              </td>
              <td className="text-xs md:text-[15px] font-montserrat max-w-xs md:max-w-sm whitespace-normal break-words">
                {demo.description}
              </td>
              <td className="text-xs md:text-[15px] font-montserrat ">
                <div className="flex">
                  <a
                    href={demo.video}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    {demo.text}
                  </a>
                  <div>{demo.video1 && <p>-Desktop Demo</p>}</div>
                </div>
                <div>
                  {demo.video1 && (
                    <div
                      className="flex mt-2 
                  "
                    >
                      <a
                        href={demo.video1}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline hover:text-blue-700"
                      >
                        Watch Video
                      </a>
                      <p>-Mobile Demo</p>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default DemoTable;
